<template>
  <div id="user-story-modal-video-player-modal" @click="onClick">
    <div id="user-story-modal-video-player-container">
      <div id="user-story-modal-video-player-title">
        {{ title }}
      </div>

      <video
        id="user-story-modal-video-player"
        :src="videoURL"
        autoplay
        controls />
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserStoryModalVideoPlayerModal",
    props: ["title", "videoURL"],
    methods: {
      onClick: function (event) {
        if (event.target.tagName === "VIDEO") {
          return; // just in case, normally does not happen if video loads properly
        }

        this.$emit("dismiss");
      },
    },
  };
</script>

<style scoped>
  #user-story-modal-video-player-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(27, 27, 27, 0.7);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #user-story-modal-video-player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #user-story-modal-video-player-title {
    user-select: none;

    text-align: center;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);

    font-size: 20px;
    font-weight: 600;

    color: #fff;
  }

  #user-story-modal-video-player {
    max-width: 80vw;
    max-height: 80vh;

    min-width: 380px;
    min-height: 280px;

    height: auto;
  }
</style>
