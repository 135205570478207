<template>
  <div id="grid-cont" :style="borderStyle">
    <UserStoryModalVideoPlayerModal
      v-if="playingVideoStory && playingVideoStory.video"
      :title="playingVideoStory.title ? playingVideoStory.title : ''"
      :videoURL="playingVideoStory.video.mp4Url"
      @dismiss="playingVideoStory = null" />

    <div id="title-cont" :style="{ 'background-color': color }">
      <div id="title-1">
        <h3>{{ widTxt[1] }}</h3>
        <h4>{{ widTxt[2] }}</h4>
      </div>
      <img src="../assets/XWhite30.png" alt="Close" @click="changeIsExpanded" />
    </div>
    <div id="profile-list-cont">
      <ul class="profile-list" v-if="school === 'WNS'">
        <li v-for="profile in profiles" :key="profile.id">
          <div class="profile2">
            <div class="prof-img-cont">
              <img
                v-if="profile.profileImageUrl"
                :src="profile.profileImageUrl"
                alt="Profile image"
                class="profile-img" />
              <img
                v-else
                src="../assets/GreyProfile.png"
                alt="Profile image"
                class="profile-img" />
            </div>
            <div id="contentContainer">
              <div class="nameContainer">
                <h5 class="nameStyling">{{ profile.first }}</h5>
                <span class="parent-name"
                  >Parent of {{ profile.profile[1] }} grader</span
                >
              </div>
              <div id="buttonContainer">
                <button
                  @click="goToChat(profile)"
                  class="profile-btn2"
                  :style="{ 'background-color': color }">
                  Message Me
                </button>
                <button
                  @click="viewProfile(profile)"
                  class="profile-btn2"
                  :style="{ 'background-color': color }">
                  View My Profile
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="profile-list" v-else>
        <li v-for="profile in profiles" :key="profile.id">
          <div class="profile">
            <div
              :class="['prof-img-cont', profile.videoStory ? 'clickable' : '']"
              @click="
                profile.videoStory
                  ? onVideoStoryClicked(profile.videoStory)
                  : ''
              ">
              <img
                v-if="profile.profileImageUrl"
                :src="profile.profileImageUrl"
                alt="Profile image"
                class="profile-img" />
              <img
                v-else
                src="../assets/GreyProfile.png"
                alt="Profile image"
                class="profile-img" />

              <div
                v-if="profile.videoStory"
                class="profile-img-video-story-container">
                <div class="profile-img-video-story">
                  <div class="profile-img-video-story-circle" />

                  <div class="profile-img-video-story-play-container">
                    <img
                      class="profile-img-video-story-play"
                      src="../assets/play_11.png" />
                  </div>
                </div>
              </div>

              <h5>{{ profile.first }}</h5>
            </div>
            <button
              v-if="canSendMessage && profile.canSendMessage"
              @click="goToChat(profile)"
              class="profile-btn"
              :style="{ 'background-color': color }">
              Message Me
            </button>
            <button
              @click="viewProfile(profile)"
              class="profile-btn"
              :style="{ 'background-color': color }">
              View My Profile
            </button>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="showProfileModal" class="modal" :style="borderStyle">
      <div class="modal-div">
        <div class="top-cont" :style="{ 'background-color': color }">
          <img
            @click="viewProfile(null)"
            src="../assets/BackArrowWhite60.png"
            alt="Back"
            class="back" />
          <div id="name-cont">
            <img
              v-if="selectedProfile.profileImageUrl"
              :src="selectedProfile.profileImageUrl"
              alt="Profile image" />
            <img v-else src="../assets/GreyProfile.png" alt="Profile image" />
            <h3>{{ selectedProfile.first + " " + selectedProfile.last }}</h3>
            <div style="height: 3px">
              <button
                v-if="canSendMessage && selectedProfile.canSendMessage"
                @click="goToChat(selectedProfile)"
                class="profile-in-btn"
                :style="{ color: color, 'border-color': color }">
                Message
              </button>
            </div>
          </div>
          <div
            class="read-my-story"
            v-if="selectedProfile.faq && selectedProfile.faq.length > 0"
            @click="viewStory(selectedProfile)">
            Read my story
          </div>
        </div>
        <div class="info-cont">
          <div v-for="(field, i) of profileFields" :key="field">
            <h4 class="field">{{ field }}</h4>
            <p class="field-a">{{ selectedProfile.profile[i] }}</p>
          </div>
        </div>
        <button
          v-if="canRequestCall && selectedProfile.requestCall"
          class="reqCall"
          @click="reqCall(selectedProfile)"
          :style="{ 'background-color': color }">
          Request a Call
          <img class="req-call-img" src="../assets/Phone.png" />
        </button>
      </div>
    </div>
    <div v-if="showStoryModal" class="modal" :style="borderStyle">
      <div class="modal-div">
        <div class="top-cont" :style="{ 'background-color': color }">
          <img
            @click="viewStory(null)"
            src="../assets/BackArrowWhite60.png"
            alt="Back"
            class="back" />
          <div id="name-cont">
            <img
              v-if="selectedProfile.profileImageUrl"
              :src="selectedProfile.profileImageUrl"
              alt="Profile image" />
            <img v-else src="../assets/GreyProfile.png" alt="Profile image" />
            <h3>{{ selectedProfile.first + " " + selectedProfile.last }}</h3>
            <div style="height: 3px">
              <button
                @click="goToChat(selectedProfile)"
                class="profile-in-btn"
                :style="{ color: color, 'border-color': color }">
                Message
              </button>
            </div>
          </div>
        </div>
        <div
          class="info-cont"
          v-for="(faq, index) in selectedProfile.faq"
          :key="index">
          <h4 class="field">{{ faq.q }}</h4>
          <h4 class="field-a">{{ faq.a }}</h4>
        </div>
        <button
          v-if="canRequestCall && selectedProfile.requestCall"
          class="reqCall"
          @click="reqCall(selectedProfile)"
          :style="{ 'background-color': color }">
          Request a Call
          <img class="req-call-img" src="../assets/Phone.png" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable no-useless-escape */

  import UserStoryModalVideoPlayerModal from "./UserStoryModalVideoPlayerModal.vue";

  export default {
    name: "ProfileList",
    components: { UserStoryModalVideoPlayerModal },
    props: [
      "color",
      "school",
      "widTxt",
      "canSendMessage",
      "canRequestCall",
      "source",
      "profiles",
      "profileFields",
    ],
    data() {
      return {
        showProfileModal: false,
        selectedProfile: null,
        showStoryModal: false,
        playingVideoStory: null,
      };
    },
    computed: {
      isMobile() {
        let check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      },
      borderStyle() {
        if (!this.isMobile) {
          return {
            "border-radius": "10px",
          };
        }
        return null;
      },
      amplitudeData() {
        let data = { org: this.school };
        if (this.source) {
          data.source = this.source;
        }
        return data;
      },
    },
    methods: {
      viewProfile(profile) {
        // pop up / close modal
        if (this.showProfileModal) {
          this.showProfileModal = false;
          this.selectedProfile = null;
        } else {
          this.showProfileModal = true;
          this.selectedProfile = profile;

          if (process.env.VUE_APP_API_KEY) {
            this.$amplitude
              .getInstance()
              .logEvent("WIDGET_PROFILE_CLICKED", this.amplitudeData);
          }
        }
      },
      viewStory(profile) {
        // pop up / close modal
        if (this.showStoryModal) {
          this.showStoryModal = false;
        } else {
          this.showStoryModal = true;
          this.selectedProfile = profile;

          if (process.env.VUE_APP_API_KEY) {
            this.$amplitude
              .getInstance()
              .logEvent("WIDGET_STORY_CLICKED", this.amplitudeData);
          }
        }
      },
      goToChat(profile) {
        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude
            .getInstance()
            .logEvent("WIDGET_MESSAGE_CLICKED", this.amplitudeData);
        }

        this.$emit("switchStates", [1, profile]);
      },
      reqCall(profile) {
        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude
            .getInstance()
            .logEvent("WIDGET_CALL_CLICKED", this.amplitudeData);
        }
        // create an emitter for the root vue componetnt of landing page to view the data needed properly.
        this.$emit("switchStates", [2, profile]);
      },
      changeIsExpanded() {
        this.$emit("changeIsExpanded");
      },
      onVideoStoryClicked(videoStory) {
        this.playingVideoStory = videoStory;

        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude
            .getInstance()
            .logEvent("WIDGET_WATCH_STORY_CLICKED", this.amplitudeData);
        }
      },
    },
  };
</script>

<style scoped>
  #grid-cont {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 100%;
    overflow-y: auto;
  }
  #title-cont {
    display: grid;
    grid-template-columns: auto 30px;
    grid-template-rows: 100%;
  }
  #title-1 {
    margin-left: 40px;
    margin-right: 10px;
  }
  #title-cont h3 {
    color: white;
    font-size: 1.5em;
  }
  #title-cont h4 {
    color: white;
    margin: 0px 0px 15px 0px;
    font-size: 1.15em;
  }
  #title-cont img {
    width: 15px;
    height: 15px;
    margin-top: 25px;
    cursor: pointer;
  }
  #title-cont img:active {
    opacity: 0.5;
  }

  .profile-list {
    list-style: none;
    padding: 0;
  }
  .profile {
    margin: 5px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 7.5px 5px;
    display: flex;
  }
  .profile2 {
    margin: 10px 10px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 7.5px 5px;
    display: grid;
    grid-template-columns: 25% auto;
  }
  .img-container {
    margin: 10px 10px 20px 10px;
    display: flex;
    justify-content: space-evenly;
  }
  .profile-img {
    height: 60px;
    width: 60px;
    border-radius: 34px;
    object-fit: cover;
    margin: 4px;
    border: 4px solid #fff;
    pointer-events: none;
    background: white;
  }
  .prof-img-cont {
    width: 80px;
  }
  .prof-img-cont h5 {
    margin: 0;
    font-size: 15px;
  }
  .profile-btn {
    margin: auto 5px;
    font-size: 15px;
    padding: 5px 10px;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }
  .profile-btn2 {
    margin: auto 5px;
    font-size: 15px;
    padding: 5px;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }
  .profile-btn:active {
    opacity: 0.5;
  }
  .profile-btn:focus {
    outline: none;
  }
  .profile-in-btn {
    margin: 0;
    font-size: 1em;
    padding: 5px 10px;
    font-weight: 600;
    background-color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 25px;
    box-sizing: border-box;
    cursor: pointer;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    transition: transform 0.2s ease-in-out;
    position: relative;
    top: 0px;
  }
  .profile-in-btn:hover {
    transform: scale(1.2);
  }
  .profile-in-btn:active {
    background-color: #e8e8e8;
  }
  .profile-in-btn:focus {
    outline: none;
  }

  .reqCall {
    padding: 10px;
    font-size: 15px;
    width: 155px;
    margin: 0px 10px 20px 10px;
    border: 0px;
    border-radius: 25px;
    border-style: solid;
    color: white;
    cursor: pointer;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: 600;
  }
  .reqCall:active {
    opacity: 0.5;
  }
  .reqCall:focus {
    outline: none;
  }

  .reqCall .req-call-img {
    box-sizing: border-box;
    float: right;
    height: 18px;
    border: 0;
    margin-right: 7px;
  }
  .modal {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    backface-visibility: hidden;
  }
  .modal-div {
    height: 90%;
    width: 90%;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
  }
  .top-cont {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 30% 40% 30%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  #name-cont {
    height: fit-content;
    margin-bottom: 15px;
  }
  #name-cont img {
    height: 100px;
    width: 100px;
    border-radius: 100px;
    margin: 15px 0 0 0;
    object-fit: cover;
    background-color: #ffffff;
  }
  #name-cont h3 {
    color: white;
    margin: 5px 0 10px 0;
  }
  .back {
    height: 30px;
    width: 30px;
    border: none;
    margin: 10px 0 0 10px;
    padding: 0;
    cursor: pointer;
  }
  .back:active {
    opacity: 0.5;
  }
  .back:focus {
    outline: none;
  }
  .info-cont {
    padding: 10px;
    padding-top: 8%;
  }
  .info-cont .field {
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0 0 0;
    text-align: left;
  }
  .info-cont .field-a {
    text-align: left;
    width: 100%;
    font-size: 15px;
    color: grey;
    margin: 5px 0 15px 0;
    font-weight: 500;
  }
  .skeleton-name {
    background-color: #d3d3d3;
    height: 20px;
    margin: 0 10px;
  }
  .skeleton-btn-small {
    background-color: #d3d3d3;
    height: 30px;
    width: 96px;
    margin: auto 5px;
    border-radius: 15px;
  }
  .skeleton-btn-large {
    background-color: #d3d3d3;
    height: 30px;
    width: 118px;
    margin: auto 5px;
    border-radius: 15px;
  }
  #buttonContainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    text-align: center;
    margin-bottom: 2%;
  }
  .nameContainer {
    margin: 0px;
    flex-direction: row;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 1%;
    margin-top: 1%;
  }
  .nameStyling {
    font-size: 20px;
    margin: 0px;
    margin-bottom: 2%;
    margin-left: 6%;
    margin-right: 4%;
  }
  .parent-name {
    font-size: 14px;
  }
  .read-my-story {
    font-size: 14px;
    color: white;
    margin-top: 15%;
    margin-right: 10%;
    text-align: right;
  }
  #contentContainer {
    display: flex;
    flex-direction: column;
  }
  .profile-img-video-story-container {
    margin-top: -80px;
    margin-left: 2px;
  }
  .profile-img-video-story {
    padding-top: 0px;
  }
  .profile-img-video-story-circle {
    height: 76px;
    width: 76px;

    box-sizing: border-box;
    border-radius: 38px;

    background: linear-gradient(to left, #0090fa, #00f6e2);
    background: -webkit-linear-gradient(to left, #0090fa, #00f6e2);
    background: -moz-linear-gradient(to left, #0090fa, #00f6e2);

    object-fit: cover;
  }
  .profile-img-video-story-play-container {
    margin-top: -20px;
  }
  .profile-img-video-story-play {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-left: 60px;
  }
  .clickable {
    cursor: pointer;
  }
</style>
