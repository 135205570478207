<template>
  <!-- TODO: add animation when switching between boxes -->
  <div v-if="isExpanded" class="box" id="large-box" :style="largeBoxStyle">
    <profile-list
      v-if="state === 0"
      :color="color"
      :school="school"
      :widTxt="widTxt"
      :canSendMessage="canSendMessage"
      :canRequestCall="canRequestCall"
      :source="source"
      :profiles="profiles"
      :profileFields="profileFields"
      @switchStates="switchStates"
      @changeIsExpanded="changeIsExpanded"></profile-list>
    <chat
      v-else-if="state === 1"
      :color="color"
      :school="school"
      :recipient="selectedUser"
      :source="source"
      @switchStates="switchStates"></chat>
    <call
      v-else-if="state === 2"
      :color="color"
      :school="school"
      :recipient="selectedUser"
      :source="source"
      @switchStates="switchStates"></call>
  </div>
  <div
    v-else-if="!isExpanded && !isMobile"
    class="box"
    id="small-box"
    @click="changeIsExpanded">
    <div>
      <h3 id="small-box-h3">{{ widTxt[0] }}</h3>
      <img
        @click.stop="changeCloseBox"
        src="../assets/XGrey30.png"
        alt="Close"
        id="close" />
    </div>
    <div class="img-container">
      <div
        v-for="profile of introProfiles"
        :key="profile._id"
        class="prof-img-cont">
        <img
          v-if="profile.profileImageUrl"
          :src="profile.profileImageUrl"
          alt="Profile image"
          class="profile-img" />
        <img
          v-else
          src="../assets/GreyProfile.png"
          alt="Profile image"
          class="profile-img" />
        <h5>{{ profile.first }}</h5>
      </div>
    </div>
    <div class="logo-cont">
      <div class="powered-by" @click.stop="goToPeerPal">
        <span> Powered by </span>

        <img
          class="powered-by-logo"
          :src="require('@/assets/peerpal-gravyty-logo.svg')" />
      </div>
    </div>
  </div>
  <div
    v-else-if="!isExpanded && isMobile"
    class="box"
    id="mobile-box"
    :style="mobileBoxStyle"
    @click="changeIsExpanded">
    <div id="mobile-box-inner">
      <h3 id="mobile-box-h3">{{ widTxt[0] }}</h3>
      <img
        @click.stop="changeCloseBox"
        src="../assets/XGrey30.png"
        alt="Close"
        id="mobile-box-close" />
    </div>
  </div>
</template>

<script>
  /* eslint-disable no-useless-escape */
  const NUM_INTRO_PROFILES = 3;

  import ProfileList from "./ProfileList";
  import Chat from "./Chat";
  import Call from "./Call.vue";

  export default {
    name: "Box",
    components: {
      "profile-list": ProfileList,
      chat: Chat,
      call: Call,
    },
    props: [
      "isExpanded",
      "color",
      "profiles",
      "profileFields",
      "school",
      "side",
      "widTxt",
      "canSendMessage",
      "canRequestCall",
      "source",
    ],
    data() {
      return {
        name: null,
        state: 0,
        selectedUser: null,
      };
    },
    computed: {
      isMobile() {
        let check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      },
      largeBoxStyle() {
        if (this.isMobile) {
          return {
            height: "100%",
            width: "100%",
            bottom: "0px",
            right: "0px",
          };
        } else {
          return {
            bottom: "75px",
            right: "0px",
            width: "380px",
            "max-height": "calc(100% - 75px)",
            border: "1px solid #e6e6e6",
            "border-radius": "10px",
          };
        }
      },
      mobileBoxStyle() {
        if (this.side === "right") {
          return { right: "0px" };
        } else if (this.side === "left") {
          return { left: "0px" };
        }
        return {};
      },
      logoColor() {
        if (this.school.includes("Iolani")) {
          return "#808080";
        }
        return "#007aff";
      },
      introProfiles() {
        return this.profiles.slice(0, NUM_INTRO_PROFILES);
      },
    },
    methods: {
      changeIsExpanded() {
        this.$emit("changeIsExpanded");
      },
      changeCloseBox() {
        this.$emit("closeBox");
      },
      switchStates(data) {
        this.state = data[0];
        this.selectedUser = data[1];
      },
      goToPeerPal() {
        parent.postMessage(
          {
            school: this.school,
            link: "https://graduway.com/products/admissions/",
          },
          "*"
        );
      },
    },
  };
</script>

<style scoped lang="scss">
  .box {
    position: fixed;
    right: 0px;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    user-select: none;
  }

  #large-box {
    position: fixed;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    user-select: none;
  }

  #small-box {
    width: 380px;
    cursor: pointer;
    bottom: 75px;
    border: 1px solid #e6e6e6;
  }

  #mobile-box {
    max-width: 380px;
    cursor: pointer;
    bottom: 75px;
    border: 1px solid #e6e6e6;
    width: fit-content;
  }

  #mobile-box-inner {
    white-space: nowrap;
  }

  h3 {
    margin: 0 0 10px 10px;
  }

  #small-box-h3 {
    margin: 10px 10px 10px 25px;
    width: calc(100% - 60px);
    float: left;
  }

  #mobile-box-h3 {
    margin: 10px 10px 10px 25px;
    float: left;
    font-size: 1em;
  }

  #close {
    margin: 15px 10px 0 0;
    height: 15px;
    width: 15px;
  }

  #mobile-box-close {
    margin: 13.5px 10px 0 0;
    height: 15px;
    width: 15px;
  }

  .img-container {
    margin: 4px 10px 10px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-evenly;
    -webkit-justify-content: space-around;
  }

  .profile-img {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    object-fit: cover;
  }

  .prof-img-cont h5 {
    margin: 0;
    font-size: 15px;
  }

  .logo-cont {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .powered-by {
      margin: 5px;
      font-family: Roboto;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #757e8c;
      display: flex;
      align-items: center;
    }

    .powered-by-logo {
      margin: 0 10px;
      height: 24px;
    }
  }
</style>
