<template>
  <div id="chat-page" :style="borderStyle">
    <div id="name-cont" :style="nameContBorderStyle">
      <h3>Success!</h3>
    </div>
    <div id="chat-cont"></div>
    <div v-if="showCallModal" class="modal" :style="borderStyle">
      <div class="modal-div">
        <div id="header-modal">
          <h4>Please provide some context for your call</h4>
          <img @click="cancelSend" src="../assets/XGrey30.png" alt="Cancel" />
        </div>
        <div v-if="!error">
          <textarea-autosize
            rows="1"
            placeholder="What do you want to discuss?"
            id="input"
            v-model="topic" />
          <textarea-autosize
            rows="1"
            placeholder="What are some times you are available?"
            id="input"
            v-model="timeFree" />
          <button @click="switchModal" :style="{ 'background-color': color }">
            Next
          </button>
          <div class="error-wrapper">
            <ul>
              <li v-for="error in modalErrors" :key="error">
                <label>{{ error }}</label>
              </li>
            </ul>
          </div>
        </div>
        <div v-else id="chat-error-wrapper">
          <label>
            Your messages won't send until you enter your information. Are you
            sure you want to exit?
          </label>
        </div>
        <button v-if="error" class="btn green-btn" @click="exitCancel">
          No
        </button>
        <button v-if="error" class="btn red-btn" @click="confirmCancel">
          Yes
        </button>
      </div>
    </div>
    <div v-if="showCallModal2" class="modal" :style="borderStyle">
      <div class="modal-div">
        <div id="header-modal">
          <h4>Please submit your contact information to receive a call</h4>
          <img @click="cancelSend" src="../assets/XGrey30.png" alt="Cancel" />
        </div>
        <div v-if="!error">
          <input type="text" placeholder="Your first name" v-model="first" />
          <input type="text" placeholder="Your last name" v-model="last" />
          <input type="text" placeholder="Your email" v-model="email" />
          <input
            type="text"
            placeholder="Phone number"
            v-model="pNumber"
            class="input-small" />
          <button @click="revertModal" :style="{ 'background-color': color }">
            Go Back
          </button>
          <button
            @click="sendContactInfo"
            :style="{ 'background-color': color }">
            Submit
          </button>
          <div class="error-wrapper">
            <ul>
              <li v-for="error in modalErrors" :key="error">
                <label>{{ error }}</label>
              </li>
            </ul>
          </div>
        </div>
        <div v-else id="chat-error-wrapper">
          <label>
            Your messages won't send until you enter your information. Are you
            sure you want to exit?
          </label>
        </div>
        <button v-if="error" class="btn green-btn" @click="exitCancel">
          No
        </button>
        <button v-if="error" class="btn red-btn" @click="confirmCancel">
          Yes
        </button>
      </div>
    </div>
    <div v-if="success" class="success-wrapper">
      <label>{{ success }}</label>
    </div>
  </div>
</template>

<script>
  /* eslint-disable no-useless-escape */

  import axios from "axios";
  export default {
    name: "Chat",
    props: ["color", "recipient", "school", "source"],
    data() {
      return {
        messageText: null,
        messages: [
          {
            id: 1,
            body: `Send me a message! I'll try to get back to you within a day or two!`,
            fromYou: false,
          },
        ],
        showCallModal: true,
        showCallModal2: false,
        first: null,
        last: null,
        email: null,
        pNumber: null,
        topic: null,
        timeFree: null,
        success: null,
        error: null,
        modalErrors: [],
      };
    },
    computed: {
      isMobile() {
        let check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      },
      borderStyle() {
        if (!this.isMobile) {
          return {
            "border-radius": "10px",
          };
        }
        return null;
      },
      nameContBorderStyle() {
        if (!this.isMobile) {
          return {
            "background-color": this.color,
            "border-top-left-radius": "10px",
            "border-top-right-radius": "10px",
          };
        }
        return {
          "background-color": this.color,
        };
      },
      amplitudeData() {
        let data = { org: this.school };
        if (this.source) {
          data.source = this.source;
        }
        return data;
      },
    },
    watch: {
      success(newSuccess) {
        //TODO: add a fade transition
        if (newSuccess) {
          setTimeout(() => {
            this.success = null;
            this.goBack();
          }, 2000);
        }
      },
    },
    methods: {
      goBack() {
        this.$emit("switchStates", [0, null]);
      },
      cancelSend() {
        if (this.error) {
          this.confirmCancel();
        } else {
          this.error = `Your messages won't send until you enter your information.`;
        }
      },
      confirmCancel() {
        this.goBack();
      },
      exitCancel() {
        this.error = null;
      },
      isEmailValid(email) {
        // eslint-disable-next-line no-useless-escape
        const reg =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        return email == "" ? false : reg.test(email) ? true : false;
      },
      switchModal() {
        if (!this.topic || !this.timeFree) {
          this.modalErrors.push(
            "Error: You need to fill out every field to submit"
          );
          return;
        } else {
          this.modalErrors = [];
        }

        this.showCallModal = false;
        this.showCallModal2 = true;
      },
      revertModal() {
        this.showCallModal = true;
        this.showCallModal2 = false;
      },
      async sendContactInfo() {
        this.modalErrors = [];
        if (
          !this.first ||
          !this.last ||
          !this.email ||
          !this.pNumber ||
          !this.topic ||
          !this.timeFree
        ) {
          this.modalErrors.push(
            "Error: You need to fill out every field to submit"
          );
          return;
        }

        if (!this.isEmailValid(this.email)) {
          this.modalErrors.push("Error: Please enter a valid email");
          return;
        }

        let response = await axios.get(`https://api.ipify.org?format=json`);
        let ip = response.data.ip;

        const reqBody = {
          first: this.first,
          last: this.last,
          email: this.email,
          pNumber: this.pNumber,
          topic: this.topic,
          timeFree: this.timeFree,
          signedUpOrg: this.school,
          recipientID: this.recipient.id,
          ip: ip,
        };

        let res;

        try {
          res = await axios.post(
            `${process.env.VUE_APP_ENDPOINT}users/createCall`,
            reqBody
          );
        } catch (err) {
          console.log(err);
        }

        // make a request with the data and include signedupOrg

        if (!res) {
          this.modalErrors.push("Something went wrong please try again");
          return;
        }

        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude.getInstance().setUserId(res.data.id);
          let identify = new this.$amplitude.Identify()
            .set("org", this.school)
            .set("currParent", false);
          this.$amplitude.getInstance().identify(identify);
          this.$amplitude
            .getInstance()
            .logEvent("WIDGET_REQ_CAll_SUBMITTED", this.amplitudeData);
        }

        this.showCallModal = false;
        this.showCallModal2 = false;
        this.success = `Call Request Submitted to ${this.recipient.first}.`;
      },
    },
  };
</script>

<style scoped>
  #chat-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  #name-cont {
    height: 50px;
    width: 100%;
  }

  #name-cont h3 {
    margin-bottom: 50px;
    padding-bottom: 50px;
    height: 100px;
    color: white;
  }

  #chat-cont {
    height: 500px;
    overflow-y: scroll;
    padding: 10px 5px;
  }

  #input {
    width: 85%;
    min-height: 40px;
    margin: 5px 0px 5px 7.5px;
    padding: 10px 15px 10px 15px;
    border-radius: 20px;
    border: 0;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    background-color: #e6e6e6;
    word-wrap: break-word;
    resize: none;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  #input:focus {
    outline: none;
  }

  .modal {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    backface-visibility: hidden;
  }

  .modal-div {
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
  }

  .modal-div input {
    width: calc(100% - 60px);
    margin: 5px 0px 5px 0px;
    padding: 10px 15px 10px 15px;
    border-radius: 20px;
    border: 0;
    font-size: 14px;
    background-color: #e6e6e6;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .modal-div input:focus {
    outline: none;
  }

  .modal-div button {
    margin: 10px 5px;
    font-size: 15px;
    padding: 10px;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    height: 40px;
    width: 100px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .modal-div button:active {
    opacity: 0.5;
  }

  .modal-div button:focus {
    outline: none;
  }

  #header-modal {
    display: grid;
    grid-template-columns: auto 30px;
    width: 100%;
  }

  #header-modal h4 {
    margin: 50px 10px 20px 40px;
  }

  #header-modal img {
    height: 20px;
    width: 20px;
    margin: 10px 10px 0 0;
    cursor: pointer;
  }
  #header-modal img:active {
    opacity: 50%;
  }

  .error-wrapper {
    overflow-y: scroll;
    margin: 0 0 10px 0;
    display: block;
  }

  .error-wrapper ul {
    list-style: none;
    padding: 0px;
    margin: 0 15px;
  }

  .error-wrapper li {
    margin: 5px 0;
    background-color: lightpink;
    border: 1px solid palevioletred;
    border-radius: 10px;
    padding: 2.5px 0;
  }

  .success-wrapper {
    position: absolute;
    top: 50%;
    width: calc(100% - 40px);
    margin: 0 20px;
    background-color: #bcf5a9;
    border: 1px solid limegreen;
    border-radius: 10px;
    padding: 2.5px 0;
  }
  #chat-error-wrapper {
    width: calc(100% - 40px);
    margin: 0 20px;
    background-color: lightpink;
    border: 1px solid palevioletred;
    border-radius: 10px;
    padding: 2.5px 0;
  }

  .btn {
    margin: auto 5px;
    font-size: 15px;
    padding: 5px;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    width: 50px;
  }
  .red-btn {
    background-color: red;
  }
  .green-btn {
    background-color: #27d507;
  }
</style>
