<template>
  <div
    class="button"
    :style="buttonStyle"
    v-if="(isExpanded && !isMobile) || !isExpanded">
    <!-- TODO: add animation when switching between pictures -->
    <svg
      v-if="isExpanded && !isMobile"
      @click="changeIsExpanded"
      class="chat-img close-button"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.5411 17.459C42.6866 17.6041 42.802 17.7765 42.8808 17.9663C42.9595 18.156 43.0001 18.3595 43.0001 18.565C43.0001 18.7705 42.9595 18.9739 42.8808 19.1637C42.802 19.3535 42.6866 19.5259 42.5411 19.671L20.6704 41.5419C20.3771 41.8352 19.9792 42 19.5644 42C19.1496 42 18.7517 41.8352 18.4584 41.5419C18.165 41.2485 18.0002 40.8507 18.0002 40.4358C18.0002 40.021 18.165 39.6231 18.4584 39.3298L40.329 17.459C40.4742 17.3135 40.6465 17.198 40.8363 17.1193C41.0261 17.0405 41.2296 17 41.4351 17C41.6406 17 41.844 17.0405 42.0338 17.1193C42.2236 17.198 42.396 17.3135 42.5411 17.459Z"
        fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.459 17.459C18.3135 17.6041 18.198 17.7765 18.1193 17.9663C18.0405 18.156 18 18.3595 18 18.565C18 18.7705 18.0405 18.9739 18.1193 19.1637C18.198 19.3535 18.3135 19.5259 18.459 19.671L40.3296 41.5419C40.623 41.8352 41.0208 42 41.4356 42C41.8505 42 42.2483 41.8352 42.5417 41.5419C42.835 41.2485 42.9998 40.8507 42.9998 40.4358C42.9998 40.021 42.835 39.6231 42.5417 39.3298L20.671 17.459C20.5259 17.3135 20.3535 17.198 20.1637 17.1193C19.9739 17.0405 19.7705 17 19.565 17C19.3595 17 19.156 17.0405 18.9662 17.1193C18.7765 17.198 18.6041 17.3135 18.459 17.459Z"
        fill="white" />
    </svg>
    <svg
      v-else-if="!isExpanded"
      @click="changeIsExpanded"
      class="chat-img"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.5 20H20.5C19.125 20 18.0125 21.125 18.0125 22.5L18 45L23 40H40.5C41.875 40 43 38.875 43 37.5V22.5C43 21.125 41.875 20 40.5 20ZM24.25 28.75H36.75C37.4375 28.75 38 29.3125 38 30C38 30.6875 37.4375 31.25 36.75 31.25H24.25C23.5625 31.25 23 30.6875 23 30C23 29.3125 23.5625 28.75 24.25 28.75ZM31.75 35H24.25C23.5625 35 23 34.4375 23 33.75C23 33.0625 23.5625 32.5 24.25 32.5H31.75C32.4375 32.5 33 33.0625 33 33.75C33 34.4375 32.4375 35 31.75 35ZM36.75 27.5H24.25C23.5625 27.5 23 26.9375 23 26.25C23 25.5625 23.5625 25 24.25 25H36.75C37.4375 25 38 25.5625 38 26.25C38 26.9375 37.4375 27.5 36.75 27.5Z"
        fill="white" />
    </svg>
    <!-- todo: make x button just completely close out (isExpanded false closeBox true) -->
  </div>
</template>

<script>
  export default {
    name: "Button",
    props: ["isExpanded", "closeBox", "color", "side"],
    computed: {
      isMobile() {
        let check = false;
        (function (a) {
          if (
            // eslint-disable-next-line no-useless-escape
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) || // eslint-disable-next-line no-useless-escape
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      },
      buttonStyle() {
        if (this.side === "left") {
          return {
            "background-color": this.color,
            left: "0px",
          };
        } else {
          return {
            "background-color": this.color,
            right: "0px",
          };
        }
      },
    },
    methods: {
      changeIsExpanded() {
        this.$emit("changeIsExpanded");
        if (this.closeBox) {
          this.$emit("openBox");
        }
      },
    },
  };
</script>

<style scoped>
  .button {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    bottom: 0px;
    border-radius: 50%;
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    user-select: none;
    pointer-events: auto;
  }

  .chat-img {
    width: 60px;
    height: 60px;
    pointer-events: auto;
  }
</style>
