import Vue from "vue";
import amplitude from "amplitude-js";

Vue.use(amplitude);

export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, "$amplitude", { value: amplitude });
    let project = amplitude.getInstance();
    project.init(process.env.VUE_APP_API_KEY);
  },
};
