<template>
  <div id="app" v-if="showWidget">
    <box
      v-show="!closeBox"
      :isExpanded="isExpanded"
      :color="color"
      :profiles="profiles"
      :profileFields="profileFields"
      :school="school"
      :side="side"
      :widTxt="widTxt"
      :source="source"
      :canSendMessage="canSendMessage"
      :canRequestCall="canRequestCall"
      @changeIsExpanded="changeIsExpanded"
      @closeBox="changeCloseBox"></box>
    <bottom-button
      :isExpanded="isExpanded"
      :closeBox="closeBox"
      :color="color"
      :side="side"
      @changeIsExpanded="changeIsExpanded"
      @openBox="openBox"></bottom-button>
  </div>
</template>

<script>
  /* eslint-disable no-useless-escape */
  /* eslint-disable no-self-assign */

  import Box from "./components/Box.vue";
  import Button from "./components/Button.vue";
  import axios from "axios";

  export default {
    name: "App",
    components: {
      box: Box,
      "bottom-button": Button,
    },
    data() {
      return {
        loaded: false,
        isExpanded: false,
        closeBox: true,
        color: "#007aff",
        profiles: [],
        school: null,
        mobileAllowed: false,
        side: "right",
        widTxt: [],
        canSendMessage: true,
        canRequestCall: false,
        source: null,
        expandedContainerHeight: "665px",
        expandedContainerHeightInterval: null,
      };
    },
    computed: {
      isMobile() {
        let check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      },
      showWidget() {
        return this.loaded && (this.mobileAllowed || !this.isMobile);
      },
      amplitudeData() {
        let data = { org: this.school, url: window.location };
        if (this.source) {
          data.source = this.source;
        }
        return data;
      },
    },
    async beforeMount() {
      this.mobileAllowed = this.$route.query.mobile !== "false";
      this.side = this.$route.query.side || "right";
      this.school = this.$route.query.school;
      this.source = this.$route.query.source || null;

      if (!this.school || (this.isMobile && !this.mobileAllowed)) {
        this.postMessage({ h: "0px", w: "0px", mobile: true });
        return;
      }

      const [brandingRes, profilesRes] = await Promise.all([
        axios.post(`${process.env.VUE_APP_ENDPOINT}users/branding`, {
          organization: this.school,
        }),
        axios.post(`${process.env.VUE_APP_ENDPOINT}users/allParents`, {
          organization: this.school,
        }),
      ]);

      // load modified widget text
      if (brandingRes.data && profilesRes.data) {
        this.profiles = profilesRes.data;

        const branding = brandingRes.data;
        this.profileFields = branding.profileFields.filter((field) => field);
        const widTxt = branding.widTxt || [];

        this.widTxt[0] = widTxt[0] || "Message one of our Parents";
        this.widTxt[1] = widTxt[1] || `Have Questions about ${this.school}?`;
        this.widTxt[2] =
          widTxt[2] ||
          "Connect with one of our parents and ask them about their experiences";
        this.canSendMessage = branding.canSendMessage;
        this.canRequestCall = branding.canRequestCall || false;

        const WHITE = "#ffffff";
        let color = this.$route.query.color?.toLowerCase();

        if (color && color !== WHITE) {
          this.color = color;
        } else if (branding.color.toLowerCase() !== WHITE) {
          this.color = branding.color;
        } else if (branding.accentColor.toLowerCase() !== WHITE) {
          this.color = branding.accentColor;
        } else if (branding.backColor.toLowerCase() !== WHITE) {
          this.color = branding.backColor;
        }

        this.closeBox = false;

        this.side = branding.widgetSide || this.side;

        this.postMessage({
          options: {
            side: this.side,
            x: (branding.widgetOffsetX || 25) + "px",
            y: (branding.widgetOffsetY || 25) + "px",
          },
        });

        if (this.isMobile) {
          this.postMessage({ h: "120px", w: "380px", mobile: false });
        } else {
          this.postMessage({ h: "270px", w: "380px", mobile: false });
        }

        this.loaded = true;
      }
    },
    mounted() {
      this.expandedContainerHeight = window.outerHeight - 200 + "px";

      if (!this.school) {
        this.postMessage({ h: "0px", w: "0px", mobile: true });
        return;
      }

      if (this.isMobile && !this.mobileAllowed) {
        this.postMessage({ h: "0px", w: "0px", mobile: true });
        return;
      }

      if (process.env.VUE_APP_API_KEY) {
        this.$amplitude
          .getInstance()
          .logEvent("WIDGET_LOADED", this.amplitudeData);
      }

      this.expandedContainerHeightInterval = setInterval(() => {
        this.expandedContainerHeight = window.outerHeight - 200 + "px";
        if (this.isExpanded && !this.isMobile) {
          this.postMessage(
            {
              h: this.expandedContainerHeight,
              w: "380px",
              mobile: false,
            },
            "*"
          );
        }
      }, 2000);
    },
    beforeDestroy() {
      clearInterval(this.expandedContainerHeightInterval);
    },
    methods: {
      postMessage(message) {
        parent.postMessage(
          {
            school: this.school,
            ...message,
          },
          "*"
        );
      },
      changeIsExpanded() {
        if (this.school === "Key School") {
          this.postMessage({ link: "https://keyschool.peerpal.app" });
          return;
        }

        this.isExpanded = !this.isExpanded;
        if (this.isExpanded && process.env.VUE_APP_API_KEY) {
          this.$amplitude
            .getInstance()
            .logEvent("WIDGET_EXPANDED", this.amplitudeData);
        }

        if (this.isExpanded && this.isMobile && this.mobileAllowed) {
          this.postMessage({ h: "100%", w: "100%", mobile: true });
        } else if (this.isExpanded && !this.isMobile) {
          this.postMessage({
            h: this.expandedContainerHeight,
            w: "380px",
            mobile: false,
          });
        } else if (!this.isExpanded && this.isMobile && this.mobileAllowed) {
          this.postMessage({ h: "120px", w: "380px", mobile: false });
        } else {
          this.postMessage({ h: "270px", w: "380px", mobile: false });
        }
      },
      changeCloseBox() {
        this.closeBox = true;
        this.postMessage({ h: "60px", w: "60px", mobile: false });
      },
      openBox() {
        this.closeBox = false;
      },
    },
  };
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
</style>
