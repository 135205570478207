import Vue from "vue";
import App from "./App.vue";
import router from "./utils/router.js";
import amplitude from "./utils/amplitude.js";
import TextareaAutosize from "vue-textarea-autosize";

Vue.config.productionTip = false;
Vue.use(TextareaAutosize);
Vue.use(amplitude);

new Vue({
  el: "#app",
  router,
  amplitude,
  render: (h) => h(App),
});
